import { ReactElement, useMemo, useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import styled from 'styled-components';

import MainLayout from 'components/layout/mainLayout';
import PageWrapper from 'components/pageWrapper';
import ContentWrapper from 'components/contentWrapper';
import ContentArea from 'components/contentArea';
import Grid from 'components/blocks/blockPartials/grid';
import Card from 'components/blocks/blockPartials/card';

import BlockButtonGroup from 'components/blocks/blockButtonGroup';

import getTemplateData from 'utils/getTemplateData';
import storyblokService from 'services/storyblokService.js';

import theme, { bp, color } from 'settings/theme';

import seo from 'utils/seo';

import {date as dateLens} from 'utils/lenses';
const lenses = {date:dateLens};


const globalAny:any = global;


// Load Review widget (We do this twice, once on script load and once in useEffect because either could load first.)
// const loadReviews = () => {
//   if(globalAny.richSnippetReviewsWidgets){
//     globalAny.richSnippetReviewsWidgets('full-page-widget', {
//       store: 'wheelfreedom',
//       widgetName: 'vertical',
//       primaryClr: '#F47E27',
//       layout:'fullWidth',
//       height: 300,
//       numReviews: 21
//     });
//   }
// };

type ContentLogitem = [string, string, any];

type Props = {
  statusCode: number,
  masterTemplateData: TemplateData,
  pageData: PageData,
  blogArticles: any,
  contentLog: ContentLogitem[]
};

const HomePage: NextPageExtended<Props> = (props) => {

  // Get content from supplied StoryBlok story
  const content = useMemo(() => {
    return props.pageData && props.pageData.content && props.pageData.content.content ? props.pageData.content.content : null
  }, [props.pageData]);

  const seoContent = useMemo(() => {
    return props.pageData && props.pageData.content && props.pageData.content.seo ? props.pageData.content.seo : null
  }, [props.pageData]);

  // Page Load
  useEffect(() => {

    console.log("Props from server", props)

    // Storyblok
    storyblokService.initEditor(null);

    // Reviews.co.uk
    // loadReviews();

  }, []);


  return (
    <>

      <Head>
        <title>{seo.createTitle(seoContent?.title)}</title>
        <meta name="description" content={seoContent?.description} />
      </Head>

      {/* Reviews.co.uk  */}
      <Head>
        <link rel="stylesheet" href="https://widget.reviews.co.uk/rating-snippet/dist.css" />
      </Head>


      {/* <Script id="reviews-rich-snippet-reviews-widgets"
        src="https://widget.reviews.co.uk/rich-snippet-reviews-widgets/dist.js"
        strategy="lazyOnload"
        onLoad={loadReviews}
      /> */}

      <StyledHomePage className="homePage">

        <PageWrapper>

          {/* Main Content */}
          <ContentArea content={content} />

          {/* Blog Articles */}
          {props.blogArticles && props.blogArticles.length > 0 &&
            <ContentWrapper>

              <div className="spacingBottomHalf">
                <h1>From Our Blog</h1>
              </div>
              
              <Grid className="spacingBottom">
                {props.blogArticles?.map(item => (
                  <Card
                    key={item.id}
                    mainImageUrl={item.content.image?.filename || item.content.image}
                    title={item.content.title}
                    subtitle={lenses.date(item.content.date)}
                    text={item.content.summary}
                    link={{
                      linktype: 'story',
                      cached_url: item.full_slug
                    }}
                    linkText="READ ARTICLE"
                  />
                ))}
              </Grid>

              <BlockButtonGroup
                block={{
                  display_settings: ['stretchOnMobile', 'centered'],
                  bottom_spacing: 'double',
                  buttons: [
                    {
                      colour: 'green',
                      text: 'Read our Blog',
                      link: {
                        linktype: 'story',
                        cached_url: 'blog/'
                      }
                    }
                  ]
                }}
              />

                
            </ContentWrapper>
          }

          {/* Reviews */}
          {/* <ContentWrapper>
            <div className="homePage-reviews spacingBottom">
              <div id="full-page-widget" style={{width:'100%'}} />
            </div>
          </ContentWrapper> */}

        </PageWrapper>
        
      </StyledHomePage>

      {storyblokService.bridge()}

    </>
  );

}

export const getStaticProps = async ({ params }) => {

  // Logging
  let contentLog: ContentLogitem[] = [];

  // Get Data
  let results;

  contentLog.push(["About to get content", (new Date()).toISOString(), null])

  try {
    results = await Promise.all([
      getTemplateData(),
      storyblokService.get(`cdn/stories/home`, {}),
      storyblokService.get(`cdn/stories`, {
        per_page: 3,
        with_parent: 8532192,
        filter_query: {
          "component": {
            "in": "blog-post"
          }
        },
        sort_by: 'content.date:desc',
        excluding_fields: 'content'
      })
    ]);

    contentLog.push(["Got data from Storyblok", (new Date()).toISOString(), results])

  } catch (error) {

    contentLog.push(["Error getting data from Storyblok", (new Date()).toISOString(), error])

    return {
      revalidate: 1, 
      props: { 
        statusCode: error.response ? error.response.status : 500,
        contentLog: contentLog
      }
    }
  }

  

  const masterTemplateData = results[0] ?? null;
  const pageData = results[1]?.data?.story ?? null;
  const blogArticles = results[2]?.data?.stories ?? null;

  return {
    props: {
      statusCode: 200,
      masterTemplateData,
      pageData,
      blogArticles,
      contentLog: contentLog
    },
    revalidate: 1
  };

};


HomePage.getLayout = (page: ReactElement) => {
  return (
    <MainLayout masterTemplateData={page.props.masterTemplateData}>
      {page}
    </MainLayout>
  )
}

export default HomePage;

const StyledHomePage = styled.div`

  .homePage-reviews{

    border: solid 1px ${color.lines};

    iframe{
      vertical-align: top;
    }

  }

  @media (min-width: ${bp(3)}) {

  }

`;